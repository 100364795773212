import React from 'react';
import { useSelector } from "react-redux";
import { Accordion, Card } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import format from '../../../utils/format';

import DetailsEnergyRatingModal from '../Detail/DetailsEnergyRatingModal';
import ContextAwareToggle from "../ContextAwareToggle";

export default (props) => {
    const currentProperty = props.property;

    let accordionOpenStatus = "0";
    if ( isMobile ) {
        accordionOpenStatus = "1";
    }

    const l = useSelector((state) => {
        return state.localizations.l;
    });

    return (
        <Accordion defaultActiveKey={accordionOpenStatus}>
            <Card>
                <ContextAwareToggle as={ Card.Header } eventKey="0">
                    <h2>
                        { currentProperty.SearchType === "ForSale" ?
                            <><i className="icon icon-proe-themify-14" /> { l.details.feesEnergyRating }</>
                            : <><i className="icon icon-proe-themify-14" /> { l.details.associatedCosts + ", " + l.details.energyRating }</>
                        }
                        
                    </h2>
                </ContextAwareToggle>
                <Accordion.Collapse eventKey="0">
                    <table className="average-price-table table">
                        <tbody>
                            { currentProperty.SearchType === "ForSale" &&
                                <>
                                <tr>
                                    <td colSpan="4" className="divider">{ l.details.fees }</td>
                                </tr>
                                <tr>
                                    <td>{ l.details.communityFees }</td>
                                    <td colSpan="3">
                                        { currentProperty.CommunityFees > 0 && <span>
                                            { format.formatCurrency(currentProperty.CommunityFees * 12, currentProperty.Currency) } /{ l.year }
                                            { " " } ({format.formatCurrency(currentProperty.CommunityFees, currentProperty.Currency)} /{ l.month })
                                        </span>}
                                        { currentProperty.CommunityFees <= 0 && <span>{ l.notAvailable }</span> }
                                    </td>
                                </tr>
                                <tr>
                                    <td>{ l.details.basuraTax }</td>
                                    <td colSpan="3">
                                        { currentProperty.BasuraTax > 0 && <span>
                                            { format.formatCurrency(currentProperty.BasuraTax, currentProperty.Currency) } /{ l.year }
                                        </span>}
                                        { currentProperty.BasuraTax <= 0 && <span>{ l.notAvailable }</span> }
                                    </td>
                                </tr>
                                <tr>
                                    <td>{ l.details.ibiFees }</td>
                                    <td colSpan="3">
                                        { currentProperty.LocalFees > 0 && <span>
                                            { format.formatCurrency(currentProperty.LocalFees, currentProperty.Currency) } /{ l.year }
                                        </span>}
                                        { currentProperty.LocalFees <= 0 && <span>{ l.notAvailable }</span> }
                                    </td>
                                </tr>
                                </>
                            }

                    { currentProperty.SearchType === "LongTermRental" && 
                    <>
                        <tr>
                            <td colSpan="4" className="divider">{ l.details.associatedCosts }</td>
                        </tr>
                        <tr>
                            <td>{ l.details.securityDeposit }</td>
                            <td colSpan="3">
                                { currentProperty.RsSecurityDeposit > 0 && 
                                    <span>
                                        { format.formatCurrency(currentProperty.RsSecurityDeposit, currentProperty.Currency) }
                                    </span>
                                }
                                { currentProperty.RsSecurityDeposit <= 0 && <span>{ l.notAvailable }</span> }
                            </td>
                        </tr>
                    </> }

                            { parseInt(currentProperty.RsEnergyRatingStatus, 10) === 1 ? <>
                                <tr>
                                    <td colSpan="4" className="divider">
                                        { l.details.energyCertificate }
                                        { currentProperty.EnergyRatingMedia &&
                                            <DetailsEnergyRatingModal energyRatingMedia={ currentProperty.EnergyRatingMedia } />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>{ l.details.co2 }</td>
                                    <td colSpan="3" className="energy-rating">
                                        <div className={ `letter-energy letter-${ currentProperty.RsCO2RatingLetter }` }>
                                            { currentProperty.RsCO2RatingLetter }
                                        </div>
                                        { format.formatNumber(currentProperty.RsCO2Rating, 2) } { l.details.co2PerYear }
                                    </td>
                                </tr>
                                <tr>
                                    <td>{ l.details.energyConsumption }</td>
                                    <td colSpan="3" className="energy-rating">
                                        <div className={ `letter-energy letter-${ currentProperty.RsEnergyRatingLetter }` }>
                                            { currentProperty.RsEnergyRatingLetter }
                                        </div>
                                        { format.formatNumber(currentProperty.RsEnergyRating, 2) } { l.details.kwhPerYear }
                                    </td>
                                </tr>
                            </> : <>
                                <tr>
                                    <td colSpan="4" className="divider">
                                        { l.details.energyCertificate }
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="4" className="emptyRow">{ l.details.energyCertificatePending }</td>
                                </tr>
                                </> 
                            }

                            <tr>
                                <td colSpan="4" className="divider">{ l.details.other }</td>
                            </tr>
                            <tr>
                                <td>{ l.details.buildYear }</td>
                                <td colSpan="3">
                                    { (currentProperty.RsCompletionYear !== '-- Select --' & currentProperty.RsCompletionYear !== '')
                                        ? <span> { currentProperty.RsCompletionYear } </span>
                                        : <span>{ l.notAvailable }</span>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}
