import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Spinner } from 'react-bootstrap';
import _ from 'underscore';
import Header from './partials/Header';
import Property from './partials/Property';
import CustomHead from './partials/CustomHead';
import request from "../agent";
import configApis from "../configs/apis";
import actions from "../actions";
import PropertyDetails from "./PropertyDetails";
import NoMatch from "./NoMatch";
import Empty from "./Empty";
import Expired from "./Expired";
import common from "../constants/common";

async function translateFeaturesData() {
    let mapFeature = {};
    try {
        const allFeatures = await request.get(`https://d34zprgbx00w5h.cloudfront.net/property/property-features.json`)
        for (const feature of allFeatures) {
            mapFeature[feature.value] = {};
            mapFeature[feature.value]['value'] = feature.label;

            let mapChild = {};
            feature.child.map(child => mapChild[child.value] = child.label)
            mapFeature[feature.value]['child'] = mapChild;
        }
    } catch (e) {
        console.error(e);
    }

    return mapFeature;
}

async function translatePropertyTypesData() {
    let mapPropertyTypes = {};
    try {
        const allPropertyTypes = await request.get(`https://d34zprgbx00w5h.cloudfront.net/property/property-types.json`)
        for (const propertyType of allPropertyTypes) {
            mapPropertyTypes[propertyType.value] = {};
            mapPropertyTypes[propertyType.value]['value'] = propertyType.label;

            let mapChild = {};
            propertyType.child.map(child => mapChild[child.value] = child.label)
            mapPropertyTypes[propertyType.value]['child'] = mapChild;
        }
    } catch (e) {
        console.error(e);
    }

    return mapPropertyTypes;
}

const ListProperties = () => {
    const dispatch = useDispatch();
    const [ expiredLinkStatus, setExpiredLinkStatus ] = useState(false);
    const [ notFound, setNotFound ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const uuid = useSelector(state => {
        return state.properties.uuid;
    });
    const paramLang = useSelector(state => {
        return state.properties.paramLang;
    });
    const rsId = useSelector(state => {
        return state.properties.rsId;
    });

    useEffect(() => {
        const fetchData = async () => {

            if (uuid === null) {
                return;
            }

            try {
                let propertiesResult = {}
                if (uuid.length > 11 && uuid.startsWith('RP-')) {
                    const featuresData = await translateFeaturesData();
                    const propertyTypesData = await translatePropertyTypesData();
                    dispatch(actions.loadPropertyFeatures(featuresData));
                    dispatch(actions.loadPropertyTypes(propertyTypesData));
                    propertiesResult = await request.get(configApis.rp_search_url.replace('{:id}', uuid));

                } else {
                    propertiesResult = await request.get(configApis.properties_url.replace('{:id}', uuid));
                }

                let {expiredLink, properties, agency, language, showPlotSqmSubTypes, searchInfo} = propertiesResult;
                let rsids = _.pluck(properties.items, 'RsId');
                let auditCodeStatus = localStorage.getItem('audit_'+uuid);

                setLoading(false);

                if (expiredLink === true) {
                    setExpiredLinkStatus(true);
                }

                if (language !== undefined) {
                    // If use set language from URI, this should be a first choise, if not pick lang from API and then default.
                    if (paramLang !== null) {
                        language = paramLang;
                    }
                    dispatch(actions.loadLocalization(language));
                }

                if (agency !== null) {
                    dispatch(actions.loadAgency(agency));
                }

                if (searchInfo !== null) {
                    dispatch(actions.loadSearchInfo(searchInfo));
                }

                if (agency !== null && properties && properties.items.length > 0) {
                    dispatch(actions.loadProperties({
                        properties: properties.items,
                        showPlotSqmSubTypes: showPlotSqmSubTypes
                    }))
                }

                if (agency !== null && properties && properties.items.length > 0 && uuid && rsId) {
                    const property = properties.items.filter(item => parseInt(item.RsId) === parseInt(rsId))
                    if (property.length > 0) {
                        dispatch(actions.setDetail(property[0]))
                    } else {
                        setNotFound(true)
                    }
                }

                if (auditCodeStatus === null) {
                    const auditData = {
                        RsIds: rsids,
                        SearchType: searchInfo.SearchType,
                        SearchVersion: searchInfo.SearchEngine === common.SEARCH_ENGINE_CORE_API
                            ? common.SEARCH_VERSION_21 : common.SEARCH_VERSION_20,
                        ContactSecureId: agency.contactSecureId,
                        AccountType: agency.accountType,
                        NewDevAccess: agency.newDevAccess,
                        Dimension: searchInfo.Dimension,
                        Currency: searchInfo.Currency
                    };
                    // Add audit of property-viewer.com
                    request.post(configApis.audit_url+'/gateway-audit/impression', auditData);
                    request.post(configApis.audit_url+'/gateway-audit/search', auditData);
                    localStorage.setItem('audit_'+uuid, JSON.stringify([]));
                }
            } catch (e) {
                console.error(e);
                setNotFound(true);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const l = useSelector((state) => {
        return state.localizations.l;
    });

    const properties = useSelector((state) => {
        return state.properties.properties;
    });

    const agency = useSelector((state) => {
        return state.agencies.agency;
    });

    const isDetail = useSelector((state) => {
        return state.properties.isDetail;
    });

    if (expiredLinkStatus === true) {
       return <Expired />;
    } else if (!loading && properties.length <= 0) {
        return <Empty />;

    } else if (notFound) {
        document.body.className = "notFound";
        return <NoMatch />;

    } else if (isDetail === true) {
        document.body.className = "inside";
        return <PropertyDetails />

    } else if (properties.length > 0) {
        document.body.className = "inside";
        return (
            <div className="container-fluid">
                { properties.length > 0 && (
                    <CustomHead properties={{
                        title: l.formatString(l.title, { agencyName: agency ? agency.name : '' }),
                        description: properties[0].Description,
                        image: properties[0].Photos[0]
                    }}/>
                ) }

                <Header />
                <div className="properties-list">
                    <div className="properties-found">{properties.length} { l.propertiesFound } </div>
                    <div className="result-list">
                        <Row>
                            { properties.map(item => (
                                <Col key={item.RsId} sm={6} md={6} lg={4} xl={3}>
                                    <Property item={item} />
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
        );
    }

    return <div className="appLoading"><Spinner animation="border" /></div>;
}

export default ListProperties;
